<template>
  <div>
    <loading v-if="isLoading" />
    <error v-else-if="isError" />
    <content-not-view v-else-if="!subPermission.index" />

    <b-card v-else>
      <div class="custom-search d-flex justify-content-between mb-1">
        <!-- search input -->
        <b-form-group>
          <div class="d-flex align-items-center">
            <label class="mr-1">{{ $t("g.searchLabel") }}</label>
            <b-form-input
              v-model="searchTerm"
              :placeholder="$t('g.searchHere')"
              type="text"
              class="d-inline-block"
            />
          </div>
        </b-form-group>
      </div>
      <!-- table -->
      <vue-good-table
        :columns="columns"
        :rows="rows"
        :rtl="direction"
        styleClass="vgt-table condensed"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm,
        }"
        :select-options="{
          enabled: true,
          selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: true, // disable the select info panel on top
          selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
        }"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
      >
        <template slot="table-column" slot-scope="props">
          <!-- Column: Name -->
          <span v-if="props.column.label === 'device_name'" class="text-nowrap">
            {{ $t("g.device_name") }}
          </span>
          <span
            v-else-if="props.column.label === 'device_id'"
            class="text-nowrap"
          >
            {{ $t("g.device_id") }}
          </span>
          <span
            v-else-if="props.column.label === 'reference_number'"
            class="text-nowrap"
          >
            {{ $t("g.reference_number") }}
          </span>
          <span v-else-if="props.column.label === 'brand'" class="text-nowrap">
            {{ $t("g.brand") }}
          </span>
          <span v-else-if="props.column.label === 'specs'" class="text-nowrap">
            {{ $t("g.specs") }}
          </span>
          <span
            v-else-if="props.column.label === 'environmental_conditions'"
            class="text-nowrap"
          >
            {{ $t("g.environmental_conditions") }}
          </span>
          <span
            v-else-if="props.column.label === 'test_standards'"
            class="text-nowrap"
          >
            {{ $t("g.test_standards") }}
          </span>
          <span
            v-else-if="props.column.label === 'working_area'"
            class="text-nowrap"
          >
            {{ $t("g.working_area") }}
          </span>
          <span
            v-else-if="props.column.label === 'device_attaches'"
            class="text-nowrap"
          >
            {{ $t("g.device_attaches") }}
          </span>
          <span
            v-else-if="props.column.label === 'maintenance_status'"
            class="text-nowrap"
          >
            {{ $t("g.maintenance_status") }}
          </span>
          <span
            v-else-if="props.column.label === 'arraival_date'"
            class="text-nowrap"
          >
            {{ $t("g.arraival_date") }}
          </span>
          <span
            v-else-if="props.column.label === 'serviceEntry_date'"
            class="text-nowrap"
          >
            {{ $t("g.serviceEntry_date") }}
          </span>
          <span
            v-else-if="props.column.label === 'working_date'"
            class="text-nowrap"
          >
            {{ $t("g.working_date") }}
          </span>
          <span
            v-else-if="props.column.label === 'out_of_service_date'"
            class="text-nowrap"
          >
            {{ $t("g.out_of_service_date") }}
          </span>

          <span v-else-if="props.column.label === 'action'" class="text-nowrap">
            {{ $t("g.action") }}
          </span>
          <span v-else>
            {{ props.column.label }}
          </span>
        </template>

        <!-- Slot: Table Row -->
        <template slot="table-row" slot-scope="props">
          <span
            v-if="props.column.field === 'maintenance_status'"
            class="text-nowrap"
          >
            {{ props.row.in_maintenance ? $t("g.open") : $t("g.close") }}
          </span>

          <span
            v-if="props.column.field === 'arraival_date_row'"
            class="text-nowrap"
          >
            {{
              props.row.arraival_date ? format(props.row.arraival_date) : "-"
            }}
          </span>
          <span
            v-if="props.column.field === 'serviceEntry_date_row'"
            class="text-nowrap"
          >
            {{
              props.row.serviceEntry_date
                ? format(props.row.serviceEntry_date)
                : "-"
            }}
          </span>
          <span
            v-if="props.column.field === 'working_date_row'"
            class="text-nowrap"
          >
            {{ props.row.working_date ? format(props.row.working_date) : "-" }}
          </span>
          <span
            v-if="props.column.field === 'out_of_service_date_row'"
            class="text-nowrap"
          >
            {{
              props.row.out_of_service_date
                ? format(props.row.out_of_service_date)
                : "-"
            }}
          </span>
          <span v-if="props.column.field === 'name_row'">
            <span v-if="subPermission.show">
              <router-link
                :to="{ name: 'EquipmentCard', params: { id: props.row.id } }"
              >
                {{ props.row.name }}
              </router-link>
            </span>
            <span v-else>{{ props.row.name }}</span>
          </span>
          <!-- Column: Action -->
          <span v-if="props.column.field === 'action'">
            <span class="actions">
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>

                <b-dropdown-item
                  v-if="subPermission.store"
                  :to="{
                    name: 'AddEquipmentCalibrationPlan',
                    params: { id: props.row.id },
                  }"
                >
                  <feather-icon icon="PlusIcon" class="mr-50" />
                  <span>{{ $t("g.add_calibration_reports") }}</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="subPermission.update"
                  :to="{ name: 'editEquipment', params: { id: props.row.id } }"
                >
                  <feather-icon icon="EditIcon" class="mr-50" />
                  <span>{{ $t("g.edit") }}</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="subPermission.destroy"
                  @click="deleteAlert(props.row.id)"
                >
                  <feather-icon icon="Trash2Icon" class="mr-50" />
                  <span>{{ $t("g.delete") }}</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap">
                {{ $t("g.pagelength") }}
              </span>
              <b-form-select
                v-model="pageLength"
                :options="['3', '5', '10']"
                class="mx-1"
                @input="
                  (value) => props.perPageChanged({ currentPerPage: value })
                "
              />
              <span class="text-nowrap">
                {{ $t("g.of") }} {{ props.total }}
                {{ $t("g.pageText") }}
              </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-card>
  </div>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BCard,
  BButton,
  BDropdownItem,
  BDropdown,
  VBTooltip,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import store from "@/store/index";
import Ripple from "vue-ripple-directive";
import Loading from "@/views/components/logic/loading.vue";
import Error from "@/views/components/logic/error.vue";
import formatDate from "@/libs/format-date";
import ContentNotView from "@/views/components/logic/contentNotView.vue";

export default {
  components: {
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BCard,
    BButton,
    Loading,
    Error,
    BDropdownItem,
    BDropdown,
    ContentNotView,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      log: [],
      pageLength: 10,
      dir: false,
      columns: [
        {
          label: "device_name",
          field: "name_row",
        },
        {
          label: "device_id",
          field: "identifier",
        },
        {
          label: "reference_number",
          field: "reference_number",
        },
        {
          label: "brand",
          field: "brand",
        },
        {
          label: "specs",
          field: "specs",
        },
        {
          label: "environmental_conditions",
          field: "environmental_conditions",
        },
        {
          label: "test_standards",
          field: "test_standards",
        },
        {
          label: "working_area",
          field: "working_area",
        },
        {
          label: "device_attaches",
          field: "device_attaches",
        },
        {
          label: "maintenance_status",
          field: "maintenance_status",
        },
        {
          label: "arraival_date",
          field: "arraival_date_row",
        },
        // {
        //   label: "serviceEntry_date",
        //   field: "serviceEntry_date_row",
        // },
        // {
        //   label: "working_date",
        //   field: "working_date_row",
        // },
        {
          label: "out_of_service_date",
          field: "out_of_service_date_row",
        },
        {
          label: "action",
          field: "action",
        },
      ],
      rows: [],
      searchTerm: "",
      isLoading: true,
      isError: false,
      format: null,
      subPermission: {},
    };
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
  },
  beforeMount() {
    this.getDevices();
    this.format = formatDate;
  },
  methods: {
    // get data from backend
    getDevices() {
      this.$http
        .get(`admin/devices`)
        .then((res) => {
          this.rows = [];
          res.data.data.map((el) => {
            if (el.in_maintenance) {
              this.rows.push(el);
            }
          });
          setTimeout(() => {
            this.isLoading = false;
            this.isError = false;
            store.dispatch("GET_PERMISSION", "devices");
            this.subPermission = store.state.permissions.sub;
          }, 1000);
        })
        .catch((err) => {
          console.log(err);
          this.isLoading = false;
          this.isError = true;
        });
    },
    deleteDevice(id) {
      this.$http
        .delete(`admin/devices/${id}`)
        .then((res) => {
          if (res.status === 200) {
            this.getDevices();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // Sweet Alert
    deleteAlert(id) {
      this.$swal({
        title: this.$t("g.areYouSure?"),
        text: this.$t("g.youWontBeAbleToRevertThis"), // You won't be able to revert this!
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.$t("g.yesDeleteIt"), // "Yes, delete it!" ,
        cancelButtonText: this.$t("g.cancel"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$swal({
            icon: "success",
            title: this.$t("g.deleted"), //"Deleted!"
            text: this.$t("g.yourFileHasBeenDeleted"), //"Your file has been deleted."
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
          this.deleteDevice(id);
        }
      });
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";

.actions {
  .dropdown-menu {
    width: 170px;
    transform: translate3d(-97px, 41px, 0px) !important;
  }
}
</style>
